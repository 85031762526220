import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/projectsStyles.css';
import FullscreenModal from './FullscreenModal';
import Image4 from '../assets/images/5k.jpeg';
import { FaDesktop, FaLanguage, FaCoffee } from 'react-icons/fa';
import TurkishFlag from '../assets/images/images.png';

function Karavan({ onClose }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');

	const openLinkedInTalha = () => {
		window.open('https://www.linkedin.com/in/talhaonder/', '_blank');
	};

	const openLinkedInBerkay = () => {
		window.open('https://www.linkedin.com/in/aliberkarad/', '_blank');
	};

	const openModal = (imageSrc) => {
		setModalImage(imageSrc);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div className='outerContainer'>
			<div className='container'>
				<button className='closeButton' onClick={onClose}>
					X
				</button>
				<div className='scrollableProjects'>
					<div className='main'>
						<div className='projectContainer'>
							<img
								src={Image4}
								alt='5K Karavan'
								className='logoImage'
							/>
							<div className='projectInfo'>
								<h2 className='projectTitle'>5K KARAVAN</h2>
								<span className='projectCategory'>
									Caravan Manufacturer
								</span>
							</div>
						</div>
					</div>

					<div className='info-container'>
						<div className='info-item'>
							<div className='icon'>
								<img
									src={TurkishFlag}
									alt='Turkish Flag'
									className='flag-icon'
								/>
							</div>
							<div className='info-text'>
								<h4>COUNTRY</h4>
								<p>Turkey</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaLanguage />
							</div>
							<div className='info-text'>
								<h4>LANGUAGE</h4>
								<p>Turkish</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaCoffee />
							</div>
							<div className='info-text'>
								<h4>CATEGORY</h4>
								<p>Internship</p>
							</div>
						</div>
						<div className='info-item'>
							<div className='icon'>
								<FaDesktop />
							</div>
							<div className='info-text'>
								<h4>PLATFORMS</h4>
								<p>Web</p>
							</div>
						</div>
					</div>

					<div className='main'>
						<h2 className='headerText'>About this Job</h2>
						<span className='projectCategory'>
							July 2023 - August 2023
						</span>
						<div>
							<p className='projectDescription'>
								🚀 Django Python Developer Internship 🚀
							</p>
							<p className='projectDescription'>
								Hello! I'm Talha Önder, and I successfully
								completed my 30-day Django Python developer
								internship. During this internship, I learned to
								create web applications using Django and Python,
								and manage my projects effectively through Git
								and GitHub.
							</p>

							<h2 className='headerText'>🛠️ My Gains</h2>
							<ul className='projectDescription'>
								<li>
									Basic Django and Python web application
									development skills.
								</li>
								<li>
									Version control and collaboration management
									using Git and GitHub.
								</li>
								<li>
									UI/UX design principles and developing
									user-friendly interfaces.
								</li>
								<li>
									Asynchronous programming and API
									integration.
								</li>
								<li>
									Database management and data storage
									techniques.
								</li>
							</ul>

							<h2 className='headerText'>📈 My Future Goals</h2>
							<ul className='projectDescription'>
								<li>
									Deepen my Django and Python skills and work
									on complex projects.
								</li>
								<li>
									Strengthen my full-stack skills by
									integrating with frontend technologies.
								</li>
								<li>
									Stay updated with innovative technologies
									and follow industry developments.
								</li>
								<li>
									Explore developer options for mobile
									applications.
								</li>
							</ul>

							<h2 className='headerText'>Information</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Role
									</span>
									<p className='projectDescription'>Intern</p>
									<span className='projectCategoryPointer'>
										Team size
									</span>
									<p className='projectDescription'>2</p>
								</div>
								<div className='projectItem'>
									<span className='projectCategoryPointer'>
										Language
									</span>
									<p className='projectDescription'>
										Turkish
									</p>
									<span className='projectCategoryPointer'>
										Country
									</span>
									<p className='projectDescription'>
										Türkiye
									</p>
								</div>
							</div>

							<h2 className='headerText'>Team</h2>
							<div className='projectsGrid'>
								<div className='projectItem'>
									<p className='projectDescription'>
										Talha Önder
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInTalha}
									>
										LinkedIn
									</span>
								</div>
								<div className='projectItem'>
									<p className='projectDescription'>
										Berkay Karadeniz
									</p>
									<span
										className='projectCategoryPointer'
										onClick={openLinkedInBerkay}
									>
										LinkedIn
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<FullscreenModal
				isOpen={isModalOpen}
				onClose={closeModal}
				imageSrc={modalImage}
			/>
		</div>
	);
}

export default Karavan;
